import request from "@/utils/axios"

export const getOssSmsCode = (telephone) => {
    return request({
        url: '/sso/getAuthCode',
        method: 'GET',
        params: {telephone}
    })
}

export const loginTo = (phone, authCode, invitationCode = null) => {
    return request({
        url: '/sso/registerAndLogin',
        method: 'POST',
        params: {phone, authCode, invitationCode: invitationCode ? invitationCode : undefined}
    })
}

export const bindCompany = (invitationCode) => {
    return request({
        url: '/member/bindCompany',
        method: 'POST',
        params: {invitationCode}
    })
}

