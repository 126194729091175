<template>
  <div class="wrap">
    <div class="top">
      <div class="top-bg-wrap"></div>
      <div class="bottom-bg-wrap"></div>
      <div class="bottom-mark"></div>
      <div class="top-content">
        <div class="title">请输入企业邀请码</div>
        <div class="code-wrap">
          <div class="input-wrap">
            <van-field v-model="invitationCode" type="number" center input-align="center" maxlength="6" size="large"/>
          </div>
          <div class="code-get" @click="copyInvitationCodeHandle">
            <van-icon name="question" size="14" color="#FF2020" />&nbsp;没有邀请码？点此获取
          </div>
        </div>
        <div class="btn-wrap">
          <van-button round block color="#FF2020" @click="bindCompanyHandle" :disabled="loading">确&nbsp;&nbsp;&nbsp;&nbsp;认</van-button>
          <van-button round block plain color="#999999" @click="backHandle">返&nbsp;&nbsp;&nbsp;&nbsp;回</van-button>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="item mb-3">
        <van-divider>企业邀请码规则</van-divider>
        邀请码是每个合作企业员工尊享众惠其珹平台福利的唯一身
        份标识。
      </div>
      <div class="item mb-5">
        <van-divider>邀请码获取方式</van-divider>
        <div class="line">1.您可咨询众惠其珹客服管家获取。</div>
        <div class="line">2.您可直接拨打专属客服电话：<span class="tel" @click="makeCallHandle">{{tel}}</span></div>
      </div>

      <van-overlay :show="customOverlayShow" lock-scroll>
        <div class="overlay-wrapper">
          <div class="inner">
            <div class="close-wrap">
              <van-icon name="clear" size="16" color="#D8D8D8" @click="customOverlayShow = !customOverlayShow"/>
            </div>
            <van-image :src="require('@/assets/images/bind-company/service-logo.png')" class="img" width="43vw" fit="scale-down"/>
            <div class="big-title">
              <span>您的专属管家</span>
              <span>正在微信等您添加</span>
            </div>
            <div class="desc">
              管家微信已复制，请前往微信添加好友获取企业邀请码
            </div>
          </div>
        </div>
      </van-overlay>
      <loading-overlay :loading="loading"/>

    </div>
  </div>

</template>

<script>
import Vue from "vue";
import {Button, Col, Divider, Field, Icon, Overlay, Row, Image, Toast} from "vant";
import VueClipboard from 'vue-clipboard2'
import {getCustomWeChatAccount, getUserToPath} from "@/utils/helper";
import {bindCompany as doBndCompany} from "@/api/login";
import LoadingOverlay from "@/components/LoadingOverlay";
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(Field)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Overlay)
Vue.use(Image)
Vue.use(VueClipboard)
export default {
  name: "BindCompany",
  components: {LoadingOverlay},
  data(){return{
    loading: false,
    invitationCode:'',
    tel:'400-138-5577',
    customOverlayShow: false,
    customWechat:getCustomWeChatAccount()
  }},
  methods:{
    copyInvitationCodeHandle(){
      this.$copyText(this.customWechat).then( ()=> {
        this.customOverlayShow = true
      },  (e)=> {
        console.log(e)
      })
    },
    makeCallHandle(){
      window.location.href = 'tel:'+this.tel
    },
    backHandle(){
      this.$router.replace({name:'login'})
    },
    bindCompanyHandle(){
      if(!this.invitationCode || this.invitationCode.length<1){
        Toast.fail('请填写企业邀请码');
        return false
      }
      this.loading = true
      doBndCompany(this.invitationCode).then(res=>{
        if(res.code === 200){
          Toast.success({
            message:'绑定成功',
            duration: 1500,
            onClose:()=>{
              this.$router.replace(getUserToPath())
            }
          })

        }else{
          Toast.fail(res.message||'绑定失败');
        }
        this.loading = false
      }, error => {
        console.log(error)
        this.loading = false
        Toast.fail('绑定出错');
      })
    },
  }
}
</script>

<style scoped lang="sass">
// 156 113
.wrap
  display: flex
  flex-direction: column
  height: 100vh
  background-color: #FFF
  .top
    display: flex
    flex-direction: column
    flex: 1
    height: 100%
    //margin-bottom: 50px
    position: relative

    //background-color: #f0f


    .top-bg-wrap, .bottom-bg-wrap, .bottom-mark
      position: absolute
      width: 100%
      height: 50%


    .top-bg-wrap
      right: 0
      top: 0
      background: url(~@/assets/images/bind-company/top_bg.png)
      background-repeat: no-repeat
      background-size: 90%
      background-position: top right


      //background-color: #fff
    .bottom-bg-wrap
      left: 0
      bottom: 0
      background: url(~@/assets/images/bind-company/bottom_bg.png)
      background-size: 90%
      background-position: left bottom
      background-repeat: no-repeat
    .bottom-mark
      width: 100%
      height: 150px
      bottom: 0
      left: 0
      background: linear-gradient(180deg, rgba(254, 254, 254, 0) 0%, #FFFFFF 100%)
      //background-color: #999999

    .top-content
      position: relative
      display: flex
      flex: 1
      //width: 100%
      flex-direction: column
      justify-content: space-around
      align-items: center
      //width: 40%
      //height: 80%
      padding: 35px 65px
      box-sizing: border-box

      //background-color: #f0f
      .title
        display: flex
        //flex-direction: column
        justify-content: center
        font-size: 18px
        font-weight: 400
        color: #333333

      .code-wrap
        display: flex
        flex-direction: column
        .input-wrap
          display: flex
          flex-direction: column
          justify-content: center
          align-content: center
          .van-cell
            border-bottom: #999999 1px solid
            background-color: transparent
            .van-field__control
              font-weight: 400
              font-size: 18px
              color: #333333
              letter-spacing: 8px

        .code-get
          display: flex
          justify-content: flex-end
          align-items: center
          color: #ff2020
          font-size: 10px
          margin: 10px 0
          cursor: pointer

    .btn-wrap
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 100%
      .van-button
        margin-top: 20px





      //background-color: #0f0
      //background-color: transparent


    // background-color: #000FFF
  .bottom
    display: flex
    flex-direction: column
    box-sizing: border-box
    font-size: 12px
    color: #999999
    line-height: 16px
    padding: 0 15px
    padding-bottom: constant(safe-area-inset-bottom)
    padding-bottom: env(safe-area-inset-bottom)
    .item
      display: flex
      flex-direction: column
      .line
        height: 16px
        align-items: center
        .tel
          color: #ff2020
          cursor: pointer
  .overlay-wrapper
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    .inner
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: 10px 15px 15px 15px
      box-sizing: border-box
      border-radius: 10px
      background-color: #fff
      .close-wrap
        display: flex
        width: 100%
        justify-content: flex-end
      .img
        margin: 13px 13px
      .big-title
        font-size: 15px
        font-weight: 400
        color: #000000
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        //margin-top: 10px
        line-height: 20px
      .desc
        margin-top: 10px
        display: flex
        flex-direction: row
        width: calc(43vw + 26px)
        font-size: 11px
        color: #999999

        .text

          width: calc(100% - 1px)

          //overflow-x: hidden
          background-color: #005500


</style>

<style lang="sass">
.top
  .code-wrap
    .input-wrap
      .van-field__control
        font-weight: 400
        font-size: 18px
        color: #333333
        letter-spacing: 8px

</style>
